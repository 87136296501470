import React from 'react'
import Img from 'gatsby-image'

import Button from '../common/Button'
import Star from '../../img/Star.svg'
import Clock from '../../img/Clock.svg'
import Science from '../../img/Science.svg'
import { APPOINTMENTS_URL } from '../../utils/urls'

import styles from './Tutorpage.module.css'

const Tutorpage = ({ bgImg, proImg, timeImg, connectedImg }) => {
  return (
    <div className={styles.page}>
      <section className={styles.headerOverlay}>
        <Img
          fluid={bgImg}
          className={styles.backImg}
          objectFit="cover"
          objectPosition="center center"
          alt="Luxo at work"
          fadeIn
        />
        <header className={styles.headerBg}>
          <h1 className={styles.header}>Feedback & Mentoring</h1>
        </header>
      </section>

      <section className={styles.bookingSection}>
        <div className={styles.headerInfo}>
          <p className={styles.info}>
            Want to learn how to be a legit music producer? Want feedback on your next track?
          </p>
          <p className={styles.info}>
            Book a live video session with Luxo, a professional music producer.
          </p>
        </div>
        <div className={styles.bookingCta}>
          <Button component="a" theme="primary" href={APPOINTMENTS_URL} target="_blank">
            Book a free call
          </Button>
        </div>
      </section>

      <div className={styles.iconSection}>
        <img className={styles.icon} src={Star} alt="Star icon" />
      </div>

      <section className={styles.contentSection}>
        <header className={styles.secTitle}>LEARN FROM A PRO</header>
        <div className={styles.bioInfo}>
          <div className={styles.bioTextContainer}>
            <p className={styles.info}>Luxo is a seasoned pro.</p>
            <p className={styles.info}>
              Mentored by the greatest and most respected underground artists in LA such as Promnite
              and Penthouse Penthouse, Luxo has risen to prominence to tour internationally in US
              and Canada.
            </p>
            <p className={styles.info}>
              Garnering +100,000 plays with his tracks on Spotify and SoundCloud, he has released
              tracks on the hottest up-and-coming House labels such as House of Hustle, Hood
              Politics, and Late Night Munchies.
            </p>
            <p className={styles.info}>
              He is also co-founder of the beloved House label Rock Bottom Records with releases
              from Morelia (Insomniac Records), DJ Susan, and MAP.
            </p>
          </div>
          <Img fluid={proImg} alt="Upclose and personal with Luxo" className={styles.prevImg} />
        </div>
      </section>

      <div className={styles.iconSection}>
        <img className={styles.icon} src={Clock} alt="Star icon" />
      </div>

      <section className={styles.contentSection}>
        <header className={styles.secTitle}>STOP WASTING TIME</header>
        <div className={styles.pitchInfo}>
          <div className={styles.pitchTextContainer}>
            <p className={styles.info}>
              Creating music is hard and doing it alone is even harder. How much time is wasted
              working without other artists’ feedback?
            </p>
            <p className={styles.info}>
              Navigating the world of the music industry takes years to learn.
            </p>
            <p className={styles.info}>
              From creating a House label, international touring, playing in festivals and clubs,
              and releasing tracks, Luxo’s years of sheer experience is wide-ranging and deep.
            </p>
            <p className={styles.info}>
              Learn ACTIONABLE tips and tricks from a music producer with real-world experience.
            </p>
            <p className={styles.info}>Take your music production to the next level.</p>
          </div>
          <Img fluid={timeImg} alt="Luxo Cheesin' " className={styles.standImg} />
        </div>
      </section>

      <div className={styles.iconSection}>
        <img className={styles.icon} src={Science} alt="Science icon" />
      </div>

      <section className={styles.contentSection}>
        <header className={styles.secTitle}>GET CONNECTED</header>
        <div className={styles.connectionInfo}>
          <div className={styles.connectionTextContainer}>
            <p className={styles.info}>
              Connect with other artists who share your interests and can support your journey.
            </p>
            <p className={styles.info}>
              Start building your artist and club network and learn what it takes to be a
              professional.
            </p>
          </div>
          <Img fluid={connectedImg} alt="Luxo and friends" className={styles.posseImg} />
        </div>
      </section>

      <section className={styles.lastWordsSection}>
        <header className={styles.bookingTitle}>READY?</header>
        <div className={styles.bookInfo}>
          <div className={styles.bookInfoText}>
            <p>Ready to take your music and your career to the next level?</p>
            <p>Click below to book a FREE phone call.</p>
          </div>
          <div>
            <Button component="a" theme="primary" href={APPOINTMENTS_URL} target="_blank">
              Book a free call
            </Button>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Tutorpage
