import React from 'react'
import Layout from '../components/Layout'
import Tutorpage from '../components/Tutorpage'
import { graphql } from 'gatsby'

const SEOProps = {
  title: 'Feedback & Mentorship',
  description:
    'Book a live video session with Luxo, a professional music producer with years of touring and production experience.',
}

const TutoringSalesPage = ({ location, data }) => (
  <Layout location={location} {...SEOProps}>
    <Tutorpage
      bgImg={data.bgImg.childImageSharp.fluid}
      proImg={data.proImg.childImageSharp.fluid}
      timeImg={data.timeImg.childImageSharp.fluid}
      connectedImg={data.connectedImg.childImageSharp.fluid}
    />
  </Layout>
)

export const query = graphql`
  query TutoringPageQuery {
    bgImg: file(relativePath: { eq: "tutoring-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    proImg: file(relativePath: { eq: "tutoring-preview.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    timeImg: file(relativePath: { eq: "tutoring-standing.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    connectedImg: file(relativePath: { eq: "luxo-posse.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 768) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default TutoringSalesPage
